import 'slick-carousel';
import AOS from 'aos';

$(function(){
  $(window).on("load",function(){
    document.onanimationend = () => {
      $(".fix-intro").remove();
    };
    $("body").addClass("start-intro");
  });
});
if(window.navigator.userAgent.indexOf("Edg") > -1){
  $("body").addClass("edge");
  $("body").addClass("start-intro");
}
if(window.navigator.userAgent.indexOf("MSIE ") > -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./)){
  $("body").addClass("ie");
  $("body").addClass("start-intro");
}
$('.kv-carousel-pc').slick(
  {
    draggable: false,
    autoplay: true,
    swipe: false,
    touchMove: false,
    fade: true,
    speed: 1000
  }
);
$('.kv-carousel-sp').slick(
  {
    draggable: false,
    autoplay: true,
    swipe: false,
    touchMove: false,
    fade: true,
    speed: 1000
  }
);
AOS.init(
  {
    offset: 300,
    once: false,
    anchorPlacement: 'top-center',
    duration: 800,
  }
);
function resizing(){
  AOS.refresh();
}
$(window).on("resize",resizing).trigger("resize");
setTimeout(() => {
  AOS.refresh();
}, 1000);
